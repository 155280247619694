// js entry point

import "./jquery.js"
import "@hotwired/turbo-rails"
import "./controllers"
import "./channels/flight_channel.js"
import "bootstrap"
import 'moment/locale/en-gb.js'
import '@fortawesome/fontawesome-free/css/all.min.css'

// not sure if we will need this
// require("@rails/activestorage").start()
