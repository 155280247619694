// update notam text automatically when max height amsl is changed

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["maxHeightAmsl", "notamText"];

    connect() {
        this.maxHeightAmslTarget.addEventListener("input", this.updateNotamText.bind(this));
        this.messageStart = this.element.dataset.notamMessageStart || "";
        this.messageEnd = this.element.dataset.notamMessageEnd || "";
    }

    disconnect() {
        this.maxHeightAmslTarget.removeEventListener("input", this.updateNotamText.bind(this));
    }

    updateNotamText() {
        let maxHeightAmsl = this.maxHeightAmslTarget.value;

        let notamText = ``;
        if (/\d/.test(maxHeightAmsl)) {
            notamText = `${this.messageStart}${maxHeightAmsl}${this.messageEnd}`;
        } else {
            notamText = `AMSL height not valid`
        }

        this.notamTextTarget.innerText = notamText;

        this.showFadeEffect();
    }

    copyToClipboard() {
        const textToCopy = this.notamTextTarget.innerText;
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        this.showCopyFlashEffect()
    }

    showFadeEffect() {
        this.notamTextTarget.classList.add("notam-fade-effect");
        setTimeout(() => {
            this.notamTextTarget.classList.remove("notam-fade-effect");
        }, 500);
    }

    showCopyFlashEffect() {
        this.notamTextTarget.classList.add("notam-flash-effect");
        setTimeout(() => {
            this.notamTextTarget.classList.remove("notam-flash-effect");
        }, 200);
    }

}
