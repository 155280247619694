// For google maps api
import { Controller } from "@hotwired/stimulus";
import * as myMap from "./maps";
import { stopLinkBeingFollowed } from "./maps";

// polygon is stored in global variable (this.polygon) and the dom as a polyPath (this.inputTarget.value)
// also stored are the drawingManager (this.drawingManager)
// lessons learned:
// add the eventlisteners in this file (and use _this) to avoid scope problems with 'this'
// this only draws polygons, not circles
export default class extends Controller {
  static targets = ['map', 'aaz', 'input', 'toggleRestricted', 'toggleCraneZones', 'safeguardingVisible']

  toggleRestricted(event) {
    stopLinkBeingFollowed(event)
    if (!this.toggleRestrictedTarget) { return }
    myMap.toggleRestricted(this.map, this.toggleRestrictedTarget);
  }

  toggleCraneZones(event) {
    stopLinkBeingFollowed(event)
    if (!this.toggleCraneZonesTarget) { return }
    myMap.toggleCraneZones(this.map, this.toggleCraneZonesTarget);
  }

  toggleSafeguarding(event) {
    stopLinkBeingFollowed(event);
    myMap.toggleSafeguarding(this.map, this.safeguardingVisibleTarget, this.safeguardingLayer)
  }

  polygonChanged(polygon_listener) {
    console.log('aaz polygon changed');
    this.polygon = polygon_listener;
    let path = google.maps.geometry.encoding.encodePath(polygon_listener.getPath());
    this.inputTarget.value = path;
  }

  addListenersToPolygon(polygon){
    var _this = this
    google.maps.event.addListener(polygon.getPath(), 'set_at', function(event){
      _this.polygonChanged(polygon)
    });

    google.maps.event.addListener(polygon.getPath(), 'insert_at', function(event){
      _this.polygonChanged(polygon)
    });

    google.maps.event.addListener(polygon.getPath(), 'remove_at', function(event){
      _this.polygonChanged(polygon)
    });

    google.maps.event.addListener(polygon.getPath(), 'dragend', function(event){
      _this.polygonChanged(polygon)
    });
  }

  deletePolygon(event) {
    console.log('aaz delete polygon');
    stopLinkBeingFollowed(event)
    if (this.polygon) { // check polygon is present before deleting
      this.polygon.setMap(null);
      this.polygon = null;
      this.inputTarget.value = ''; // save to dom
      myMap.showDrawingManager(this.drawingManager)
    }
  }

  polygonComplete(polygon_listener) {
    console.log('aaz polygon complete');
    let path = google.maps.geometry.encoding.encodePath(polygon_listener.getPath());
    this.inputTarget.value = path;
    this.polygon = polygon_listener;
    myMap.hideDrawingManager(this.drawingManager);
    this.addListenersToPolygon(this.polygon); // allow editing
  }

  connect() {
    console.log('aaz map edit controller');

    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");

    let type = this.data.get("type");
    if (type === null) { type = 'flight' }; // default to flight if undefined

    let map = new google.maps.Map(this.mapTarget, myMap.defaultMapOptions(mapCentreLat, mapCentreLon));
    this.map = map;

    // myMap.drawAAZPolygons(map);
    if (type === 'flight') {
      myMap.drawRestricted(map);
    } else {
      myMap.drawCraneZones(map);
    }

    if (this.hasSafeguardingVisibleTarget) {
      this.safeguardingLayer = myMap.drawSafeguardingLayer(map)
    }

    myMap.addGetBoundsFunction();

    let drawingManager = myMap.setUpAazDrawingManager(map);

    this.polygon = null;
    this.drawingManager = drawingManager;

    let polyPath = this.inputTarget.value;

    if (polyPath) { // polygon already present
      myMap.hideDrawingManager(drawingManager); // hide drawing manager if a polygon is already present
      this.polygon = myMap.drawPolygonFromPath(map, polyPath);
      this.polygon.setOptions(myMap.polyEditOptions());
      this.addListenersToPolygon(this.polygon); // allow editing
    }

    var _this = this;
    google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon_listener){
      _this.polygonComplete(polygon_listener);
    });

  }

}
