import airspaces from './geo_data/restricted.json';

//////////////////////////////////////////////////////////////////
const AIRSPACE_STYLES = { strokeColor: '#ff0000', strokeOpacity: 1, strokeWeight: 0.2, fillColor: '#B08080', fillOpacity: 0.1, icon: {url: ''} }
const AAZ_STYLES = { strokeWeight: 0.5, strokeColor: 'blue', fillOpacity: 0 }
const DRAWING_MANAGER_STYLES = { strokeWeight: 1, editable: true, draggable: true }

////////////////////////////////////////////

// draw polygon and return it
export function drawPolygonFromPath(map, path, colour = 'black', weight = 1, opacity = 0.05) {
    return new google.maps.Polygon({
        paths: google.maps.geometry.encoding.decodePath(path),
        strokeWeight: weight,
        fillOpacity: opacity,
        strokeColor: colour,
        clickable: true, // need this to respond to mouseover events
        map: map
    });
}

// draw circle and return it
export function drawCircleFromPath(map, centrePath, radius, colour = 'black', weight = 1, opacity = 0.05) {
    return new google.maps.Circle({
        center: google.maps.geometry.encoding.decodePath(centrePath)[0],
        radius: parseInt(radius, 10),
        strokeWeight: weight,
        fillOpacity: opacity,
        strokeColor: colour,
        clickable: true, // need this to respond to mouseover events
        map: map
    });
}

// aaz has a different style
export function drawAAZPolygonFromPath(map, path) {
    let props = { paths: google.maps.geometry.encoding.decodePath(path), map: map };
    return new google.maps.Polygon({ ...AAZ_STYLES, ...props });
}

// drop pin on polygon - flights are blue and cranes are green
export function addPinToPolygon(map, poly, applicationType) {
    const icon = (applicationType == 'craneApplication') ? 'https://labs.google.com/ridefinder/images/mm_20_green.png' :'https://labs.google.com/ridefinder/images/mm_20_blue.png'
    addGetBoundsFunction();
    return new google.maps.Marker({
        position: poly.getBounds().getCenter(), // put it in the centre
        map: map,
        icon: icon,
    })
}

// drop pin on circle
export function addPinToCircle(map, circle) {
    return new google.maps.Marker({
        position: circle.center,
        map: map,
        icon: 'https://labs.google.com/ridefinder/images/mm_20_blue.png',
    })
}

////////////////////////////////////////////

export function setUpAazDrawingManager(map) {
    let dm = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: true,
        drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon']
        },
        polygonOptions: DRAWING_MANAGER_STYLES,
    });
    dm.setMap(map);
    return dm;
}

////////////////////////////////////////////

export function setUpFormMapDrawingManager(map, applicationType='FlightApplication') {
    if (applicationType == 'FlightApplication') { // show polygon drawing tool
        let dm = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['circle', 'polygon']
            },
            circleOptions: DRAWING_MANAGER_STYLES,
            polygonOptions: DRAWING_MANAGER_STYLES,
        });
        dm.setMap(map);
        return dm;
    } else { // craneApplication - was no polygon but added polygon back so cranes nd drones are the same
        let dm = new google.maps.drawing.DrawingManager({
            drawingMode: null,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['circle', 'polygon']
            },
            circleOptions: DRAWING_MANAGER_STYLES,
            polygonOptions: DRAWING_MANAGER_STYLES,
        });
        dm.setMap(map);
        return dm;
    }
}

////////////////////////////////////////////

export function hideDrawingManager(dm) {
    dm.setOptions({ drawingMode: null,  drawingControl: false, });
}

////////////////////////////////////////////

export function showDrawingManager(dm) {
    dm.setOptions({ drawingMode: null,  drawingControl: true, });
}

export function addDrawingManagerListeners(dm) {
    dm.setOptions({ drawingMode: null,  drawingControl: true, });
}

////////////////////////////////////////////

// editable options for a polygon or circle
export function polyEditOptions() {
    return { editable: true, draggable: true }
}

//////////////////////////////////////////////////////////////////

export function mouseover(card, polygon, _pin) {
    // console.log('mouseover for card', card.dataset.id)
    card.classList.add("card-mouseover")
    setMapHighlight(card, polygon)
    // pin.setOptions({icon: 'https://labs.google.com/ridefinder/images/mm_20_blue.png',});
}

export function mouseout(card, polygon, _pin) {
    // console.log('mouseout for card', card.dataset.id)
    card.classList.remove("card-mouseover")
    setMapHighlight(card, polygon)
    // pin.setOptions({icon: 'https://labs.google.com/ridefinder/images/mm_20_red.png',});
}

export function setMapHighlight(card, shape) {
    if (card) {
        if (card.classList.contains('card-mouseover')) {    // mouse highlight so blue
            shape.setOptions({strokeColor: 'black', fillColor: '#8EB0FF', fillOpacity: 0.5})
        } else {
            if (card.dataset.activated == 'true') {         // activated so red
              shape.setOptions({strokeColor: 'black', fillColor: 'red', fillOpacity: 0.4});
            } else {                                        // no highlight at all
                shape.setOptions({strokeColor: 'black', fillColor: 'black', fillOpacity: 0.05})
            }
        }
    }
}

//////////////////////////////////////////////////////////////////

export function zoomToShape(map, shape) {
    if (shape == null) { return; }
    map.fitBounds(shape.getBounds());
}

//////////////////////////////////////////////////////////////////

// Draw UK FRZs, danger areas and prohibited
export function drawRestricted(map) {
    map.data.addGeoJson(airspaces)
    map.data.setStyle(AIRSPACE_STYLES)
}

export function toggleRestricted(map, element) { // element is the target storing the data attribute
    if (element.dataset.visible == 'true') {
        element.dataset.visible = 'false'
        map.data.setStyle({visible: false})
        console.log('toggleRestricted - set to false')
    } else {
        element.dataset.visible = 'true'
        map.data.setStyle(AIRSPACE_STYLES)
        console.log('toggleRestricted - set to true')
    }
}

export function showRestricted(map, element) {
    element.dataset.visible = 'true';
    map.data.setStyle(AIRSPACE_STYLES)
}
//////////////////////////////////////////////////////////////////

// Draws the 6km circle around the key airfields:
//   Warton: 53.745, -2.883333
//   Gatwick: 51.148056, -0.190278
//   Blackpool: 53.77166667, -3.02861111
//   Walney: 54.12861111, -3.2675
export function drawCraneZones(map) {
    const options = {radius: 6000, strokeWeight: 0.5, fillOpacity: 0.05, strokeColor: '#000000', fillColor: '#000000', clickable: false, map: map}
    let result = []
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(53.745, -2.883333)}))
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(51.148056, -0.190278)})) // Gatwick
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(53.77166667, -3.02861111)})) // Blackpool
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(54.12861111, -3.2675)})) // Walney
    return result
}

// Add them but invisible
export function setupCraneZones(map) {
    const options = {radius: 6000, strokeWeight: 0.5, fillOpacity: 0.05, strokeColor: '#000000', fillColor: '#000000', clickable: false, visible: false, map: map}
    let result = []
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(53.745, -2.883333)}))
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(51.148056, -0.190278)})) // Gatwick
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(53.77166667, -3.02861111)})) // Blackpool
    result.push(new google.maps.Circle({...options, center: new google.maps.LatLng(54.12861111, -3.2675)})) // Walney
    return result
}

export function toggleCraneZones(element, craneZones) {
    if (element.dataset.visible == 'true') {
        console.log('toggleCraneZones - set to false')
        element.dataset.visible = 'false'
        craneZones.forEach(function(zone) { zone.setVisible(false) })
    } else {
        console.log('toggleCraneZones - set to true')
        element.dataset.visible = 'true'
        craneZones.forEach(function(zone) { zone.setVisible(true) })
    }
}
//////////////////////////////////////////////////////////////////

// Draws the safeguarding surfaces from a KML
export function drawSafeguardingLayer(map) {
    const src = 'https://staging.airportal.aero/kmz/ihs.kml'
    let safeguardingLayer = new google.maps.KmlLayer(src, {
        suppressInfoWindows: true,
        preserveViewport: true,
        map: map
    })
    return safeguardingLayer
}

export function toggleSafeguarding(map, element, safeguardingLayer) {
    if (element.dataset.visible == 'true') {
        console.log('toggle safeguarding Layer - set to false')
        element.dataset.visible = 'false'
        safeguardingLayer.setMap(null)
    } else {
        console.log('toggle safeguarding Layer - set to true')
        element.dataset.visible = 'true'
        safeguardingLayer.setMap(map)
    }
}

//////////////////////////////////////////////////////////////////

// On dashboard page, the AAZ polygons are stored in AAZ targets
// hidden by default
export function drawAAZPolygons(map, element, aazTargets) {
    if (element) { // will not be present for an operator's form
        let aazs = aazTargets.map(function(target) { // draw aazs
            return drawAAZPolygonFromPath(map, target.dataset.polygon)
        })
        hideAAZPolygons(element, aazs)
        return aazs;
    }
}

export function toggleAAZPolygons(element, aazs) {
    if (element.dataset.visible == 'true') {
        element.dataset.visible = 'false'
        console.log('toggleAAZPolygons - set to false')
        aazs.forEach(function(aaz) { aaz.setVisible(false) })
      } else {
        element.dataset.visible = 'true'
        console.log('toggleAAZPolygons - set to true')
        aazs.forEach(function(aaz) { aaz.setVisible(true) })
      }

}

export function hideAAZPolygons(element, aazs) {
    element.dataset.visible = 'false';
    aazs.forEach(function(aaz) { aaz.setVisible(false) })
  }

//////////////////////////////////////////////////////////////////

export function addGetBoundsFunction() {
    google.maps.Polygon.prototype.getBounds = function() {
        var bounds = new google.maps.LatLngBounds();
        var paths = this.getPaths();
        var path;
        for (var i = 0; i < paths.getLength(); i++) {
            path = paths.getAt(i);
            for (var ii = 0; ii < path.getLength(); ii++) {
                bounds.extend(path.getAt(ii));
            }
        }
        return bounds;
    }
}

////////////////////////////////////////////

/**
 * Converts decimal degrees to degrees minutes seconds.
 *
 * @param dd the decimal degrees value.
 * @param isLng specifies whether the decimal degrees value is a longitude.
 * @return degrees minutes seconds string in the format 49°15'51.35"N
 */
function convertToDms(dd, isLng) {
    var dir = dd < 0
      ? isLng ? 'W' : 'S'
      : isLng ? 'E' : 'N';

    var absDd = Math.abs(dd);
    var deg = absDd | 0;
    var frac = absDd - deg;
    var min = (frac * 60) | 0;
    var sec = frac * 3600 - min * 60;
    // Round it to 2 decimal points.
    sec = Math.round(sec * 100) / 100;
    return deg + "°" + min.toString().padStart(2, '0') + "'" + sec.toString().padStart(2, '0') + '"' + dir;
  }

// shape can be 'polygon' or 'circle'
//  if 'polygon' then 'path' is the polygon path
//  if 'circle' then 'path' is the circle path and radius is provided too
export function drawCoordinatesTable(shape, path, radius) {
    if (document.querySelector('#coordinates-table') == null)
        return;

    if (shape == 'polygon') {
        var tableDoc = document.createElement('table');
        tableDoc.innerHTML = '<p class="mb-0">Polygon</p>';
        var head = ['Latitude', 'Longitude'];
        var coordinates = new Array();
        var data = google.maps.geometry.encoding.decodePath(path);
        for (var i = 0; i <= data.length - 1; i++) {
            var item = data[i].toJSON();
            coordinates.push([convertToDms(item.lat), convertToDms(item.lng.toFixed(5), true)]);
        }
        var tr = tableDoc.insertRow(-1);
        for (var h = 0; h < head.length; h++) {
            var th = document.createElement('th');
            th.innerHTML = head[h];
            tr.appendChild(th);
        }
        for (var c = 0; c <= data.length - 1; c++) {
            tr = tableDoc.insertRow(-1);

            for (var j = 0; j < head.length; j++) {
                var td = document.createElement('td');
                td = tr.insertCell(-1);
                td.innerHTML = coordinates[c][j];
            }
        }
        document.querySelector('#coordinates-table').childNodes[1].replaceWith(tableDoc);
    } else { // circle
        var div = document.createElement('div');
        let centre = google.maps.geometry.encoding.decodePath(path)[0];
        var item = centre.toJSON();
        div.innerHTML = `<p class="mb-0">Circle</p>Lat: ${convertToDms(item.lat)}<br>Lon: ${convertToDms(item.lng, true)}<br>Radius: ${Math.round(radius)}m<p>`
        document.querySelector('#coordinates-table').childNodes[1].replaceWith(div);
    }
}

////////////////////////////////////////////

export function removeCoordinatesTable() {
    if (document.querySelector('#coordinates-table') !== null) {
        let none = document.createTextNode("none");
        document.querySelector('#coordinates-table').childNodes[1].replaceWith(none);
    }
}
////////////////////////////////////////////

export function defaultMapOptions(mapCentreLat, mapCentreLon) {
    // TODO allow custom default map zoom
    // mapCentreLat = mapCentreLat || 53.422532; // default to dublin
    // mapCentreLon = mapCentreLon || -6.265908; // default to dublin
    mapCentreLat = mapCentreLat || 53.745; // default to warton
    mapCentreLon = mapCentreLon || -2.883333; // default to warton
    // Good options:
    // Warton: 53.745, -2.883333
    // Gatwick: 51.148056, -0.190278
    // London heliport: 51.47000000, -0.17944444
    //
    return { center: new google.maps.LatLng(mapCentreLat, mapCentreLon), zoom: 12 };
}

//////////////////////////////////////////////////////////////////

export function stopLinkBeingFollowed(event) { // handy util to stop a link being followed, used for all maps
    event.preventDefault();
    event.stopPropagation();
}
