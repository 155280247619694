// For google maps api
import { Controller } from "@hotwired/stimulus"
import * as myMap from "./maps";
import {defaultMapOptions} from "./maps";

// the map is stored in a global (this.map)
export default class extends Controller {
  static targets = ['map', 'zone']

  // draw polygon and return it
  drawPolygonFromJson(map, json_path, colour = 'red', weight = 0.4, opacity = 0) {
    return new google.maps.Polygon({
      paths: json_path,
      strokeWeight: weight,
      fillOpacity: opacity,
      strokeColor: colour,
      clickable: true, // need this to respond to mouseover events
      map: map
    });
  }

  connect() {
    console.log('airport zone map controller');

    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");

    let map = new google.maps.Map(this.mapTarget, myMap.defaultMapOptions(mapCentreLat, mapCentreLon));
    this.map = map;

    let _this = this
    for (let zone of this.zoneTargets) {
      const coords = JSON.parse(zone.innerHTML);
      if (coords !== null) {
        _this.drawPolygonFromJson(map, coords);
      }
    }
  }

}
