// For google maps api
import { Controller } from "@hotwired/stimulus"
import * as myMap from "./maps"
import {defaultMapOptions, setupCraneZones, toggleCraneZones} from "./maps"
import { stopLinkBeingFollowed } from "./maps"

// this.map is saved as a global
export default class extends Controller {
  static targets = ['map', 'droneAaz', 'toggleDroneAazs', 'craneAaz', 'toggleCraneAazs', 'toggleRestricted', 'toggleCraneZones' ];

  activateShape(appId) { // Highlights shape on map
    const shape = this.mapShapes[appId]['shape'];
    const card = document.getElementById(`card-${appId}`);
    myMap.setMapHighlight(card, shape)
    // const pin = this.mapShapes[appId]['pin'];
    // pin.setOptions({icon: 'https://labs.google.com/ridefinder/images/mm_20_orange.png',});
  }

  deactivateShape(appId) {
    const shape = this.mapShapes[appId]['shape'];
    const card = document.getElementById(`card-${appId}`);
    myMap.setMapHighlight(card, shape)
    // const pin = this.mapShapes[appId]['pin'];
    // pin.setOptions({icon: 'https://labs.google.com/ridefinder/images/mm_20_red.png',}); // unchanged
  }

  toggleRestricted(event) {
    stopLinkBeingFollowed(event);
    myMap.toggleRestricted(this.map, this.toggleRestrictedTarget);
  }

  toggleCraneZones(event) {
    stopLinkBeingFollowed(event);
    myMap.toggleCraneZones(this.toggleCraneZonesTarget, this.craneZones);
  }

  toggleDroneAazs(event) {
    if (this.hasToggleDroneAazsTarget) {
      console.log('toggle drone aazs');
      stopLinkBeingFollowed(event)
      myMap.toggleAAZPolygons(this.toggleDroneAazsTarget, this.droneAazs);
    }
  }

  toggleCraneAazs(event) {
    if (this.hasToggleCraneAazsTarget) {
      console.log('toggle crane aazs');
      stopLinkBeingFollowed(event)
      myMap.toggleAAZPolygons(this.toggleCraneAazsTarget, this.craneAazs);
    }
  }

  resetMap(event) {
    console.log('reset map');
    stopLinkBeingFollowed(event)
    let mapCentreLat = this.data.get("mapCentreLat")
    let mapCentreLon = this.data.get("mapCentreLon")
    this.map.setOptions(defaultMapOptions(mapCentreLat, mapCentreLon))
    myMap.hideAAZPolygons(this.toggleAazsTarget, this.aazs)
    myMap.showRestricted(this.map, this.toggleRestrictedTarget)
  }

  setInfoWindowContent(card, infowindow, pin) {
    // nb card.dataset.appStatus is unused because status pill formatting only available in ruby - would have to re-write it in js
    infowindow.close();
    let startText = `<h2 class="small mb-1">${card.dataset.appRef}</h2>`
    let endText = `<div class="small">${card.dataset.appDates}</div>`
    let middleText = ""
    if (card.dataset.appRole == 'viewer') { // do not provide dynamic link to viewers
      middleText = `<h4>${card.dataset.appName}</h4>`
    } else {
      middleText = `<h4><a href=${card.dataset.appLink}>${card.dataset.appName}</a></h4>`
    }
    infowindow.setContent(startText + middleText + endText);
    infowindow.open({ map: myMap, anchor: pin, shouldFocus: true });
  }

  setupMapAndCards() {
    // let result = {}
    let cards = document.getElementsByClassName('card');
    for (let card of cards) { // set up the shapes but do not draw
      this.addShapeButDoNotDraw(card.dataset.appId)
    }
    for (let card of cards) { // highlight any cards that are activated
      if (card.dataset.activated === 'true') {
        console.log('Activating at load')
        card.classList.add('card-flight-activated')
        // this.activateShape(card.dataset.appId)
      }
    }
    // WE USED TO BE DRAWING ONLY THE VISIBLE CARD SHAPES
    // NOW WE DRAW ALL SHAPES
    // this.drawMapShapesForVisibleCards()
    this.drawMapShapesForAllCards()
  }

  reloadMapShapes() {
    console.log('Reload map shapes after a FILTER change')
    this.hideAllMapShapes()
    this.setupMapAndCards()
  }

  addShapeButDoNotDraw(appId) {
    // console.log('Adding map shape: ', appId)
    let card = document.getElementById(`card-${appId}`);
    let polyPath = card.dataset.polygon; // can't use data.get because it is not unique to controller
    let circlePath = card.dataset.circleCentre;
    let circleRadius = card.dataset.circleRadius;
    let applicationType = card.dataset.appType;
    let shape = (polyPath) ? myMap.drawPolygonFromPath(null, polyPath) : myMap.drawCircleFromPath(null, circlePath, circleRadius);
    let pin = myMap.addPinToPolygon(null, shape, applicationType);
    let _this = this;
    pin.addListener('mouseover', function () { myMap.mouseover(card, shape, pin) });
    pin.addListener('mouseout', function () { myMap.mouseout(card, shape, pin) });
    pin.addListener('click', function () { _this.setInfoWindowContent(card, _this.infowindow, pin) });
    card.addEventListener('mouseover', function () { myMap.mouseover(card, shape, pin) });
    card.addEventListener('mouseout', function () { myMap.mouseout(card, shape, pin) });
    card.querySelector('.zoom-link').addEventListener('click', function (event) {
      stopLinkBeingFollowed(event);
      myMap.zoomToShape(_this.map, shape)
    }, false);
    this.mapShapes[appId] = {'pin': pin, 'shape': shape, 'card': card}
  }

  hideAllMapShapes() {
    for (let appId in this.mapShapes) {
      let shape = this.mapShapes[appId]
      shape['pin'].setMap(null);
      shape['shape'].setMap(null);
    }
  }

  // draw map shape if card is present and visible and activate it if necessary
  drawMapShapesForVisibleCards() {
    for (let card of document.getElementsByClassName('card')) {
      let tabpaneElement = card.closest(".tab-pane")
      let tabpane = window.getComputedStyle(tabpaneElement); // tab pane (ancestor of the card) is hidden if not active
      if ((tabpane.display === 'none') || (tabpane.visibility === 'hidden')) {
        continue // skip hidden cards
      }
      // now check if it is a nested tab-pane and the parent is hidden
      let tabpane2 = window.getComputedStyle(tabpaneElement.parentNode.closest(".tab-pane"))
      if (tabpane2 && ((tabpane2.display === 'none') || (tabpane2.visibility === 'hidden'))) {
        continue // skip hidden cards
      }
      let shape = this.mapShapes[card.dataset.appId]
      // console.log('showing map shape', card.dataset.appId)
      shape['pin'].setMap(this.map);
      shape['shape'].setMap(this.map);
      this.activateShape(card.dataset.appId)
    }
  }

  // draw map shape for all cards whether visible or not
  drawMapShapesForAllCards() {
    for (let card of document.getElementsByClassName('card')) {
      let shape = this.mapShapes[card.dataset.appId]
      shape['pin'].setMap(this.map);
      shape['shape'].setMap(this.map);
      this.activateShape(card.dataset.appId)
    }
  }

  // cards have been updated by changing tabs
  // which triggers a global event: window.dispatchEvent(new CustomEvent("tab-changed"))
  // this is handled by the dashboard controller which calls this function
  // clear them and redraw but leave rest of map unchanged
  updateAfterTabChange() {
    // NO LONGER USED - ALL SHAPES ON PERMENANTLY!
    // console.log('Update shape visibilities after a TAB change')
    // this.hideAllMapShapes()
    // this.drawMapShapesForVisibleCards()
  }

  recentre(event) {
    // console.log('recentre')
    stopLinkBeingFollowed(event)
    let coords = JSON.parse(event.currentTarget.dataset.coords)
    this.map.setCenter({lat: coords[0], lng: coords[1]})
  }

  connect() {
    console.log('dashboard map controller connected')
    this.mapShapes = {} // a sort of global

    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");

    let map = new google.maps.Map(this.mapTarget, myMap.defaultMapOptions(mapCentreLat, mapCentreLon));
    this.map = map;
    this.infowindow = new google.maps.InfoWindow({content: ""})

    myMap.drawRestricted(map);
    if (this.hasToggleDroneAazsTarget) {
      this.droneAazs = myMap.drawAAZPolygons(map, this.toggleDroneAazsTarget, this.droneAazTargets)
    }
    if (this.hasToggleCraneAazsTarget) {
      this.craneAazs = myMap.drawAAZPolygons(map, this.toggleCraneAazsTarget, this.craneAazTargets)
    }

    this.craneZones = myMap.setupCraneZones(map)
    myMap.addGetBoundsFunction();

    this.setupMapAndCards();

  }

}
