// Submit the select list to create an approval request

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  request() {
    const form =  this.element.querySelector('form');
    form.submit();
  }

}
