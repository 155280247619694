// Monitors the general filter for changes and submits the form
// Only works with select lists, not other items (like checkboxes)

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['letter', 'search']
  static values = { selectedletter: String, autofocussearch: String }

  connect() {
    // if a search field has autofocus field is then put the cursor at the end of text (otherwise whole box will highlight and you can't carry on typing!)
    const searchInput = document.querySelector(`input[name="${this.autofocussearchValue}"]`);
    if (searchInput && searchInput.autofocus) {
        const len = searchInput.value.length;
        searchInput.focus();
        searchInput.setSelectionRange(len, len);
    }
  }

  submitFilter(event) {
    let el = this.element.querySelector('form');
    let url = el.getAttribute('action');
    let selects = this.element.querySelectorAll('select');

    let array = [];

    // run through the select boxes
    for (let i = 0; i < selects.length; i++) {
      let name = selects[i].getAttribute('name');
      let value = selects[i].options[selects[i].selectedIndex].value;
      array.push(`${name}=${value}`);
    }

    // and the search fields
    this.searchTargets.forEach(function(searchInput) {
        if (searchInput.value != '') {
            array.push(`${searchInput.name}=${searchInput.value}`);
        }
    })
    // if triggered by search then set that for autofocus
    if (event.target.name) {
      array.push(`autofocus_search=${event.target.name}`)
    }

    // if alphabet letter selected then get the letter or toggle off letter if already selected
    if (event.params['letter']) { // passed in as a parameter
        if ( this.selectedletterValue == event.params['letter']) {
            // do not append and it will be removed from the filter
        } else {
            array.push(`letter=${event.params['letter']}`);
        }
    } else { // letter was previously selected so retain it
        array.push(`letter=${this.selectedletterValue}`);
    }

    let urlParams = array.join('&');

    Turbo.visit(url + '?' + urlParams);
  }

}
