import { Controller } from "@hotwired/stimulus"
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import * as threeHelper from "./three_helper"

const FRZ_MESH = {color: 0xecf23d, transparent: true, opacity: 0.1}

// TODO add different views for cranes and flights - currently everything treated as a flight
export default class extends Controller {
  static targets = ['aaz', 'frzkml', 'frz', 'rpz', 'flight'];

  // Draws an FRZ or RPZ from coordinate string which is JSON
  // height in feet
  drawJSONpoly(string, height, scene, wartonARP) {
    const parsed = JSON.parse(string)
    const jsonCoords = threeHelper.getCoords(parsed)
    const pathXY = jsonCoords.map(point => threeHelper.distanceAndBearing(wartonARP.lat, wartonARP.lng, point[1], point[0]))
    const shape = new THREE.Shape()
    shape.moveTo(pathXY[0][0], pathXY[0][1])
    for (let i = 0; i < pathXY.length; i++) { shape.lineTo(pathXY[i][0], pathXY[i][1]) }
    const geometry = new THREE.ExtrudeGeometry(shape, {depth: threeHelper.feetToZ(height), bevelEnabled: false});
    const material = new THREE.MeshPhongMaterial(FRZ_MESH);
    const edges = new THREE.EdgesGeometry(geometry);
    const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x222222 }));
    scene.add(line);
    const mesh = new THREE.Mesh(geometry, material); // add this in to give color to faces
    scene.add(mesh);
  }

  // target dataset has 4 possible attributes
  // polygon, circle-centre, circle-radius and height
  drawFlight(target, scene, wartonARP){
    const poly = target.dataset.polygon
    const centre = target.dataset.circleCentre
    const meshParameters = {color: 0xff00ff, transparent: true, opacity: 0.1}
    if (poly == "" && centre == "") {
      return
    }
    const height = target.dataset.height
    if (poly !== "") {
      const path = google.maps.geometry.encoding.decodePath(poly)
      const pathJSON = path.map(point => point.toJSON())
      const pathXY = pathJSON.map(point => threeHelper.distanceAndBearing(wartonARP.lat, wartonARP.lng, point.lat, point.lng))
      const shape = new THREE.Shape()
      shape.moveTo(pathXY[0][0], pathXY[0][1])
      for (let i = 0; i < pathXY.length; i++) { shape.lineTo(pathXY[i][0], pathXY[i][1]) }
      const geometry = new THREE.ExtrudeGeometry(shape, {depth: threeHelper.feetToZ(height), bevelEnabled: false});
      const material = new THREE.MeshPhongMaterial(meshParameters);
      const edges = new THREE.EdgesGeometry(geometry);
      const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x222222 }));
      scene.add(line);
      // const flight= new THREE.Mesh(geometry, material);
      // scene.add(flight);
    } else {
      const radius = target.dataset.circleRadius
      const path = google.maps.geometry.encoding.decodePath(centre)
      const pathJSON = path.map(point => point.toJSON())
      const pathXY = pathJSON.map(point => threeHelper.distanceAndBearing(wartonARP.lat, wartonARP.lng, point.lat, point.lng))
      const centreXY = pathXY[0]
      const geometry = new THREE.CylinderGeometry( radius/1000 , radius/1000, threeHelper.feetToZ(height), 32 );
      geometry.rotateX(Math.PI * 0.5)
      geometry.translate(centreXY[0], centreXY[1], 0)
      const material = new THREE.MeshPhongMaterial(meshParameters);
      const edges = new THREE.EdgesGeometry(geometry);
      const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x222222 }));
      scene.add(line);
      // const flight= new THREE.Mesh(geometry, material);
      // scene.add(flight);
    }
  }

  drawAAZ(target, scene, wartonARP) {
    const path = google.maps.geometry.encoding.decodePath(target.dataset.polygon)
    const pathJSON = path.map(point => point.toJSON())
    const pathXY = pathJSON.map(point => threeHelper.distanceAndBearing(wartonARP.lat, wartonARP.lng, point.lat, point.lng))
    const shape = new THREE.Shape()
    shape.moveTo(pathXY[0][0], pathXY[0][1])
    for (let i = 0; i < pathXY.length; i++) { shape.lineTo(pathXY[i][0], pathXY[i][1]) }
    const geometry4 = new THREE.ExtrudeGeometry(shape, {depth: threeHelper.feetToZ(target.dataset.height), bevelEnabled: false});
    const material4 = new THREE.MeshPhongMaterial({color: 0xff0000, transparent: true, opacity: 0.1});
    const edges = new THREE.EdgesGeometry(geometry4);
    const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x222222 }));
    scene.add(line);
    const aaz = new THREE.Mesh(geometry4, material4);
    scene.add(aaz);
  }

  drawARP(x,y,color, scene) {
    const geometry = new THREE.ConeGeometry(.2, 0.4);
    geometry.translate(0,0.25, 0)
    geometry.rotateX(Math.PI * 0.5)
    geometry.translate(x, y,0 )
    const material = new THREE.MeshPhongMaterial({color: color});
    const arp = new THREE.Mesh(geometry, material);
    scene.add(arp);
  }

  drawfrzkml(target, scene, wartonARP) {
  const cordString = this.frzkmlTarget.dataset.coords.split(",").map((x) => parseFloat(x)); // convert to array of floats
  let coordStringSplit = []
  for (let i = 0,j = cordString.length; i < j; i += 3) { coordStringSplit.push(cordString.slice(i, i + 3)) }
  const height = threeHelper.metreToZ(coordStringSplit[0][2])
  const pathXY = coordStringSplit.map(point => threeHelper.distanceAndBearing(wartonARP.lat, wartonARP.lng, point[1], point[0]))
  const shape = new THREE.Shape()
  shape.moveTo(pathXY[0][0], pathXY[0][1])
  for (let i = 0; i < pathXY.length; i++) { shape.lineTo(pathXY[i][0], pathXY[i][1]) }
  const geometry = new THREE.ExtrudeGeometry(shape, {depth: height, bevelEnabled: false});
  const material = new THREE.MeshPhongMaterial(FRZ_MESH);
  const edges = new THREE.EdgesGeometry(geometry);
  const line = new THREE.LineSegments(edges, new THREE.LineBasicMaterial({ color: 0x222222 }));
  scene.add(line);
  const frzkml = new THREE.Mesh(geometry, material);
  scene.add(frzkml);
  }

  connect() {
    THREE.Object3D.DefaultUp.set(0, 0, 1);
    const scene = new THREE.Scene();
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);

    // Background
    scene.background = new THREE.Color(0x5cd3ff);
    const light = new THREE.DirectionalLight(0xffffff, 0.7);
    light.position.set(0.5, 1.0, 0.5).normalize();
    scene.add(light);
    const light4 = new THREE.AmbientLight(0x808080); // soft white light
    scene.add(light4);

    // axes helper
    // const axesHelper = new THREE.AxesHelper(5);
    // scene.add(axesHelper);

    // Camera
    const camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 1, 500);
    camera.position.set(-1, -20, 10);
    scene.add(camera);

    // Orbit controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI * 0.495;

    // Grid
    const grid = new THREE.GridHelper(50, 50, 0xdddddd, 0x000044);
    grid.rotateX(Math.PI * 0.5)
    scene.add(grid);

    // Floor
    const geometry3 = new THREE.PlaneGeometry(50, 50, 1, 1);
    const material3 = new THREE.MeshPhongMaterial({ color: 0x51bb8e });
    const floor = new THREE.Mesh(geometry3, material3);
    floor.translateZ(-0.01) // 10 m does to avoid line interference
    scene.add(floor);

    // ARP at 0, 0
    this.drawARP(0,0,0xffffff, scene)

    // Blackpool
    const blackpoolARP = {lat: 53.77166667, lng: -3.02861111} //534618N 0030143
    const wartonARP = {lat: 53.745098, lng: -2.883060} //534442.09N 0025300.37
    const [x,y] = threeHelper.distanceAndBearing(wartonARP.lat, wartonARP.lng, blackpoolARP.lat, blackpoolARP.lng);
    this.drawARP(x,y, 0x00ffff, scene)

    // AAZs, FRZs, RPZ and flight applications
    // all drawn using google shape or JSON
    let _this = this;
    this.aazTargets.map(function(target) {
      _this.drawAAZ(target, scene, wartonARP)
    });
    this.frzTargets.map(function(target) { // includes rpzs
      _this.drawJSONpoly(target.dataset.coords, 2000, scene, wartonARP) // TODO fix setting height manually
    })
    this.flightTargets.map(function(target) {
      _this.drawFlight(target, scene, wartonARP) // height is a hack
    })
    this.frzkmlTargets.map(function(target) {   // This one is drawn from kml
      _this.drawfrzkml(target, scene, wartonARP)
    });

    // Animate
    const animate = function () {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate()
  }
}
