const VERTICAL_SCALE = 4

// Converts feet to Z axis
// Vertical scale is x4
export function feetToZ(feet) {
    return feet * 0.0003048 * VERTICAL_SCALE
}

export function metreToZ(metre) {
    return metre / 1000 * VERTICAL_SCALE
}

// From https://www.movable-type.co.uk/scripts/latlong.html
export function distanceAndBearing(lat1, lon1, lat2, lon2) {
    // haversine calc
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI/180; // φ, λ in radians
    const φ2 = lat2 * Math.PI/180;
    const Δφ = (lat2-lat1) * Math.PI/180;
    const Δλ = (lon2-lon1) * Math.PI/180;
    const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ/2) * Math.sin(Δλ/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const d = R * c; // in metres
    // bearing calc
    const y = Math.sin(Δλ) * Math.cos(φ2);
    const x = Math.cos(φ1)*Math.sin(φ2) - Math.sin(φ1)*Math.cos(φ2)*Math.cos(Δλ);
    const θ = Math.atan2(y, x); // this is the bearing
    const xcalc = d * Math.sin(θ) / 1000;
    const ycalc = d * Math.cos(θ) / 1000;
    return [xcalc, ycalc]; // in km
}

export function getCoords(parsedString) {
    if (parsedString.geometry.type == "Polygon") {
        return parsedString.geometry.coordinates[0]
    }
    if (parsedString.geometry.type == "MultiPolygon") {
        return parsedString.geometry.coordinates[0][0]
    }
}
