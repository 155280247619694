// Prevents sending a message if content is blank

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['content'];

  submitMessage(event) {
    if (this.contentTarget.value.length == 0) {
      event.preventDefault()
      event.stopPropagation()
    }

  }

}
