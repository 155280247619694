import consumer from "./consumer"

consumer.subscriptions.create("FlightChannel", {
  // ALL THESE METHODS HAVE BEEN REPLACED IN THE STIMULUS CONTROLLERS SO NOT REQUIRED HERE
  // SEE DASHBOARD AND ROW FLIGHT ACTIVATION CONTROLLERS
  // JUST LEFT HERE FOR INFORMATION

  connected() {
    // Called when the subscription is ready for use on the server
    // console.log('Action cable connected');
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    // console.log('Action cable disconnected');
  },

  received(data) {
  //   Called when data received from the server
  }
});
