// For google maps api
import { Controller } from "@hotwired/stimulus"
import * as myMap from "./maps";
import {defaultMapOptions, toggleSafeguarding, zoomToShape} from "./maps";
import { stopLinkBeingFollowed } from "./maps";

// for flight entry and editing
// polygon is stored in global variable (this.polygon) and the dom as a polyPath (this.polygonTarget.value)
// also stored is the drawingManager (this.drawingManager) and map (this.map)
// lessons learned:
// add the eventlisteners in this file (and use _this) to avoid scope problems with 'this'
export default class extends Controller {
  static targets = ['map', 'polygon', 'circleCentre', 'circleRadius', 'toggleRestricted', 'toggleCraneZones', 'safeguardingVisible', 'aazs'];
  static values = {'applicationType': String}

  ////////////////////// display overlays

  toggleRestricted(event) {
    stopLinkBeingFollowed(event)
    myMap.toggleRestricted(this.map, this.toggleRestrictedTarget);
  }

  toggleCraneZones(event) {
    stopLinkBeingFollowed(event);
    myMap.toggleCraneZones(this.toggleCraneZonesTarget, this.craneZones)
  }

  toggleSafeguarding(event) {
    stopLinkBeingFollowed(event);
    myMap.toggleSafeguarding(this.map, this.safeguardingVisibleTarget, this.safeguardingLayer)
  }
  ////////////////////// polygons

  polygonComplete(polygon_listener) {
    console.log('polygon complete');
    this.polygon = polygon_listener;
    let path = google.maps.geometry.encoding.encodePath(this.polygon.getPath());
    this.polygonTarget.value = path;
    myMap.hideDrawingManager(this.drawingManager);
    myMap.drawCoordinatesTable('polygon', path);
    this.addListenersToPolygon(this.polygon);
    this.deleteCircle(); // double check!
  }

  deletePolygon() {
    this.polygon = null;
    this.polygonTarget.value = '';
  }

  polygonChanged(polygon_listener) {
    console.log('polygon changed');
    this.polygon = polygon_listener;
    this.polygonTarget.value = google.maps.geometry.encoding.encodePath(this.polygon.getPath());
    let path = google.maps.geometry.encoding.encodePath(this.polygon.getPath());
    myMap.drawCoordinatesTable('polygon', path);
  }

  addListenersToPolygon(polygon){
    var _this = this;
    google.maps.event.addListener(polygon.getPath(), 'set_at', function(){_this.polygonChanged(polygon)});
    google.maps.event.addListener(polygon.getPath(), 'insert_at', function(){_this.polygonChanged(polygon)});
    google.maps.event.addListener(polygon.getPath(), 'remove_at', function(){_this.polygonChanged(polygon)});
    google.maps.event.addListener(polygon.getPath(), 'dragend', function(){_this.polygonChanged(polygon)});
  }

  ////////////////////// circles

  circleComplete(circle_listener) {
    console.log('circle complete');
    this.circle = circle_listener;
    this.circleCentreTarget.value = google.maps.geometry.encoding.encodePath([this.circle.center]);
    this.circleRadiusTarget.value = this.circle.radius;
    myMap.hideDrawingManager(this.drawingManager);
    myMap.drawCoordinatesTable('circle', this.circleCentreTarget.value, this.circle.radius);
    this.addListenersToCircle(this.circle);
    this.deletePolygon(); // double check!
  }

  deleteCircle() {
    this.circle = null;
    this.circleCentreTarget.value = '';
    this.circleRadiusTarget.value = '';
  }

  circleChanged(circle_listener) {
    console.log('circle changed');
    this.circle = circle_listener;
    this.circleCentreTarget.value = google.maps.geometry.encoding.encodePath([this.circle.center]);
    this.circleRadiusTarget.value = this.circle.radius;
    myMap.drawCoordinatesTable('circle', this.circleCentreTarget.value, this.circle.radius);
  }

  addListenersToCircle(circle){
    var _this = this;
    google.maps.event.addListener(circle, 'radius_changed', function(){_this.circleChanged(circle)});
    google.maps.event.addListener(circle, 'center_changed', function(){_this.circleChanged(circle)});
  }

  ////////////////////// other stuff

  addListenersToDrawingManager(dm) {
    var _this = this;
    google.maps.event.addListener(dm, 'polygoncomplete', function (polygon_listener) {
      _this.polygonComplete(polygon_listener);
    });
    google.maps.event.addListener(dm, 'circlecomplete', function (circle_listener) {
      _this.circleComplete(circle_listener);
    });
  }

  deleteShape(event) {
    console.log('delete shape');
    if (event) {
      stopLinkBeingFollowed(event)
    }
    if (this.polygon !== null) {
      this.polygon.setMap(null);
      this.deletePolygon();
    }
    if (this.circle !== null) {
      this.circle.setMap(null);
      this.deleteCircle();
    }
    myMap.showDrawingManager(this.drawingManager)
    myMap.removeCoordinatesTable();
    this.addListenersToDrawingManager(this.drawingManager)
  }

  zoomMap(event) {
    console.log('zoom map');
    if (event) { stopLinkBeingFollowed(event); }
    if (this.polygon != null) {
      myMap.zoomToShape(this.map, this.polygon);
    } else if (this.circle != null) {
      myMap.zoomToShape(this.map, this.circle);
    }
  }

  resetMap(event) {
    console.log('reset map');
    if (event) { stopLinkBeingFollowed(event); }
    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");
    this.map.setOptions(defaultMapOptions(mapCentreLat, mapCentreLon));
    if (this.applicationTypeValue == 'FlightApplication') { myMap.showRestricted(this.map, this.toggleRestrictedTarget) }
  }

  ////////////////////// polygon stuff

  openUploadModal(event) {
    console.log('Open upload modal');
    stopLinkBeingFollowed(event);
    $('#upload-kml-file-modal').modal({show: true})
  }

  receiveUploadedKMLPolygon(_this, uploadedAppId, map, drawingManager) {
    console.log('Update map with new polygon')
    _this.deleteShape()
    _this.resetMap()
    let encoded = uploadedAppId.innerText
    _this.polygonTarget.value = encoded
    _this.polygon = myMap.drawPolygonFromPath(map, encoded)
    zoomToShape(map, _this.polygon)
    myMap.drawCoordinatesTable('polygon', encoded)
    myMap.hideDrawingManager(drawingManager)
    _this.polygon.setOptions(myMap.polyEditOptions()) // allow editing
    _this.addListenersToPolygon(_this.polygon)
  }

  initialize() {
    let craneZones = []
  }

  connect() {
    console.log('form map controller');

    let locked = (this.data.get("locked") === 'true');

    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");

    let map = new google.maps.Map(this.mapTarget, myMap.defaultMapOptions(mapCentreLat, mapCentreLon));

    // myMap.drawAAZPolygons(map); // will only be present in view for airports
    if (this.applicationTypeValue == 'FlightApplication') { // only draw FRZs or airspace for flight applications
      myMap.drawRestricted(map);
    }
    if (this.applicationTypeValue == 'CraneApplication') { // draw 6km circle for crane applications
      this.craneZones = myMap.drawCraneZones(map);
    }
    if (this.hasSafeguardingVisibleTarget) {
      this.safeguardingLayer = myMap.drawSafeguardingLayer(map)
    }

    myMap.addGetBoundsFunction();

    let drawingManager = myMap.setUpFormMapDrawingManager(map,this.applicationTypeValue);
    this.polygon = null;
    this.circle = null;
    this.drawingManager = drawingManager;
    this.map = map;

    let polyPath = this.polygonTarget.value;
    let circleCentrePath = this.circleCentreTarget.value;
    let circleRadius = this.circleRadiusTarget.value;

    if (polyPath) { // polygon already present
      this.polygon = myMap.drawPolygonFromPath(map, polyPath);
      myMap.drawCoordinatesTable('polygon', polyPath);
      myMap.hideDrawingManager(drawingManager); // hide drawing manager if a polygon is already present
      if (!locked) { // allow editing
        this.polygon.setOptions(myMap.polyEditOptions());
        this.addListenersToPolygon(this.polygon);
      }
    }

    if (circleCentrePath) { // circle already present
      this.circle = myMap.drawCircleFromPath(map, circleCentrePath, circleRadius);
      myMap.drawCoordinatesTable('circle', circleCentrePath, circleRadius);
      myMap.hideDrawingManager(drawingManager); // hide drawing manager if a circle is already present
      if (!locked) { // allow editing
        this.circle.setOptions(myMap.polyEditOptions());
        this.addListenersToCircle(this.circle);
      }
    }

    if (!polyPath && !circleCentrePath && !locked) { // new form, add listeners
      this.addListenersToDrawingManager(drawingManager)
    }

    // add listener for uploaded KML
    const uploadedKMLDiv = document.getElementById('uploaded-kml-div')
    let _this = this
    if (uploadedKMLDiv) {
      let observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
          _this.receiveUploadedKMLPolygon(_this, uploadedKMLDiv, map, drawingManager)
        })
      })
      observer.observe(uploadedKMLDiv, { attributes: true, childList: true, characterData: true });
    }
  }

}
