// Upload a KML or KMZ file and return a polygon for map display and saving

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['errorMessage', 'file', 'form']

    closeModal(event) {
        event.preventDefault(event)
        const errorMessage = this.errorMessageTarget
        const form = this.formTarget
        $('#upload-kml-file-modal').modal('hide')
        $(errorMessage).html('')
        form.reset()
    }

    upload(event) {
        event.preventDefault(event)

        const errorMessage = this.errorMessageTarget
        const fileField = this.fileTarget
        const form = this.formTarget

        $(errorMessage).html('')

        let formData = new FormData()
        // to post forms we need to add the authenticity token
        const token_element = document.head.querySelector('meta[name="csrf-token"]')
        if (token_element) { // is not present in tests, so need this to avoid erroring
            formData.append('authenticity_token', token_element.content)
        }

        if (fileField.files && fileField.files[0]) {
            let file = fileField.files[0]
            formData.append('uploaded_flight_area_file', file, file.name)
            $.ajax({
                url: "/convert_kml",
                type: "POST",
                data: formData,
                dataType: 'json',
                contentType: false, // this and line below are to make formData work with authenticity token
                processData: false,
                success: function (response) {
                    const encoded = google.maps.geometry.encoding.encodePath(response['coordinates'])
                    $('#uploaded-kml-div').html(encoded) // this will trigger form_map_controller
                    $('#upload-kml-file-modal').modal('hide')
                    $(errorMessage).html('')
                    form.reset()
                },
                error: function (response, _textStatus, _xhr) { // :bad_request
                    $(errorMessage).html(response.responseText)
                    form.reset()
                }
            })
        } else {
            $(errorMessage).html("No file selected")
        }

    }
}
