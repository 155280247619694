// For google maps api
import { Controller } from "@hotwired/stimulus"
import * as myMap from "./maps";
import {defaultMapOptions} from "./maps";
import { stopLinkBeingFollowed } from "./maps"

// the map is stored in a global (this.map)
export default class extends Controller {
  static targets = ['map', 'aaz', 'toggleRestricted', 'toggleCraneZones', 'safeguardingVisible']

  toggleRestricted(event) {
    stopLinkBeingFollowed(event)
    if (!this.toggleRestrictedTarget) { return }
    myMap.toggleRestricted(this.map, this.toggleRestrictedTarget);
  }

  toggleCraneZones(event) {
    stopLinkBeingFollowed(event)
    if (!this.toggleCraneZonesTarget) { return }
    myMap.toggleCraneZones(this.toggleCraneZonesTarget, this.craneZones);
  }

  toggleSafeguarding(event) {
    stopLinkBeingFollowed(event);
    myMap.toggleSafeguarding(this.map, this.safeguardingVisibleTarget, this.safeguardingLayer)
  }

  resetMap(event) {
    console.log('reset map')
    stopLinkBeingFollowed(event)
    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");
    this.map.setOptions(defaultMapOptions(mapCentreLat, mapCentreLon));
    if (this.data.get('type') == 'flight') { myMap.showRestricted(this.map, this.toggleRestrictedTarget) }
  }

  connect() {
    console.log('aaz map controller');

    let mapCentreLat = this.data.get("mapCentreLat");
    let mapCentreLon = this.data.get("mapCentreLon");

    let map = new google.maps.Map(this.mapTarget, myMap.defaultMapOptions(mapCentreLat, mapCentreLon));
    this.map = map;

    if (this.data.get('type') == 'flight') { myMap.drawRestricted(map) }
    if (this.data.get('type') == 'crane') { this.craneZones = myMap.drawCraneZones(map) }

    if (this.hasSafeguardingVisibleTarget) {
      this.safeguardingLayer = myMap.drawSafeguardingLayer(map)
    }

    myMap.addGetBoundsFunction();

    for (let aaz of this.aazTargets) {
      let polyPath = aaz.dataset.polygon; // can't use data.get because it is not unique to controller
      myMap.drawAAZPolygonFromPath(map, polyPath); // draw aaz polygons
    }

  }

}
