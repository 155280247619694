// Auto saves a form when it changes

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['success', 'fail']

    save() { // don't need to test if form is disabled as js is disabled then anyway
        const success = this.successTarget
        const fail = this.failTarget

        const form = this.element.querySelector('form')
        let formData = new FormData(form)

        // to post forms we need to add the authenticity token
        const token_element = document.head.querySelector('meta[name="csrf-token"]')
        if (token_element) { // is not present in tests, so need this to avoid erroring
            formData.append('authenticity_token', token_element.content)
        }

        $.ajax({
            url: this.data.get('url'),
            type: "PUT",
            data: formData,
            dataType: 'json',
            contentType: false, // this and line below are to make formData work with authenticity token
            processData: false,
            success: function(_response, _textStatus, xhr) {
                // console.log('autosave success');
                if (xhr.status === 204) {
                    success.classList.remove('hide');
                    setTimeout(function () {
                        success.classList.add('hide');
                    }, 800)}
                else {
                    // console.log('autosave fail');
                    fail.classList.remove('hide');
                    setTimeout(function() {
                        fail.classList.add('hide');
                    }, 800);}
                },
            error: function(_response, _textStatus, _xhr) {
                // console.log('autosave fail - network problem');
                fail.classList.remove('hide');
                setTimeout(function() {
                    fail.classList.add('hide');
                }, 800);},
          })

    }

}
