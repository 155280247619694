// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/themes/airbnb.css";

export default class extends Flatpickr {

    initialize() {
        const defaultDate = this.data.get("the-default-date");
        this.config = {
            defaultDate: Date.parse(defaultDate),
            dateFormat: "d M Y",
            // minDate: "today",
            // noCalendar: true,
        };
    }

    // returns a variable from the url eg '?days=7' - pass in 'days' to get '7'
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++) {
            var pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return(false);
    }

    // all flatpickr hooks are available as callbacks in your Stimulus controller
    change(selectedDates, dateStr, _instance) {
        // console.log(dateStr);
        // let date = new Date.parse(dateStr)
        let queryParams = '?date_from=' + dateStr; //.toISOString().split('T')[0]; //.replace(/\s+/g, '-'); // also replace ' ' with '-'
        let days = this.getQueryVariable('days');
        if (days) {
            queryParams = queryParams + '&days=' + days;
        }
        let url_root = window.location.href.split('?')[0];
        let url = url_root + queryParams;
        // console.log('calling getResults with url:', url);
        this.filterController.getResults(url);
    }

    get filterController() {
        return this.application.getControllerForElementAndIdentifier(document.getElementById("dashboard-filter-id"), "dashboard-filter")
    }
}