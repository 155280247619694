import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }

// Setup global features
$(document).on('turbo:load', function() {

    // autohide minor alerts
    setTimeout(function(){
        $('.autohide-alert').fadeOut(2500)
    }, 2000);

    // initialise tooltips
    $(function () {
        $('[data-toggle="tooltip"]').tooltip({placement: 'top', delay: 0, container: 'body'})
    })

})

// After a bootstrap tab change, trigger a custom global event
// It will be picked up by dashboard map controllers to reload the shapes
// (Need to wait until tab is shown otherwise contents may not be visible yet)
$(document).on('shown.bs.tab', 'a[data-toggle="tab"]', function (e) {
    // console.log("Tab changed")
    const event = new CustomEvent("tab-changed")
    window.dispatchEvent(event)
})