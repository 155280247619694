// Disables a form if it is locked
// Also re-sizes any form text to show the whole

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'form' ];

  connect() {
    const form = this.formTarget;
    const locked = (this.data.get("locked") === "true");
    const disableEnterKey = (this.data.get("disable-enter-key") === "true");

    if (locked) {
      form.classList.add('dp-form-disabled');

      form.querySelectorAll("input").forEach(function(item) {
        item.disabled = true;
      });

      form.querySelectorAll("textarea").forEach(function(item) {
        item.disabled = true;
      });

      form.querySelectorAll("input") // lock checkboxes and similar
          .forEach(input => input.addEventListener("click", (function (event) {
              event.preventDefault();
              event.stopPropagation();
              })
            )
          )

      form.querySelectorAll("select") // lock select list drop downs
          .forEach(input => input.addEventListener("mousedown", (function (event) {
                event.preventDefault();
                event.stopPropagation();
              })
            )
          )
    }

    // prevent enter key submitting the form since some forms have multiple submit buttons and it gets confused
    // off by default
    if (disableEnterKey) {
      form.querySelectorAll("input")
        .forEach(input => input.addEventListener("keydown", (function (event) {
          if (event.which == 13) { // Enter key
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        })))

      form.querySelectorAll("select")
        .forEach(input => input.addEventListener("keydown", (function (event) {
          if (event.which == 13) { // Enter key
            event.preventDefault();
            event.stopPropagation();
            return false;
          }
        })))
    }

    // resize any input text_areas with class 'resize-me'
    // because otherwise they will not resize in the form if they are too long
    form.querySelectorAll(".resize-me").forEach(function(textarea) {
      // console.log(textarea);
      textarea.style.height = "auto";
      textarea.style.height = (textarea.scrollHeight + 2) + "px";
    });

   }

}
