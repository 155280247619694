// The dashboard filter controller

import { navigator } from '@hotwired/turbo'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log('Dashboard filter controller connected - now trigger filter changed');
    // Controller will re-load after user changes filter, so we can hook into this event
    // to trigger map to get the shapes reloaded
    // This global event will be picked up by dashboard-map controller
    console.log('Dashboard filter controller connected - now trigger filter changed');
    const event = new CustomEvent("filter-changed")
    window.dispatchEvent(event)
  }

  async getResults(url) {
    // console.log('Dashboard filter getResults with url:', url)
    history.pushState({}, null, url.toString())
    navigator.history.push(new URL(url))
    fetch(url, {
      headers: { Accept: "text/vnd.turbo-stream.html" },
    }).then(r => r.text())
        .then(html => Turbo.renderStreamMessage(html))
  }

  filter(event) {
    const url = event.target.href
    // console.log('dashboard filter (turbo stream) with link:', url)
    if (url == null) { return }
    event.preventDefault()
    this.getResults(url)
  }

  get dashboardMapController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("dashboard-map-id"), "dashboard-map")
  }

}
