// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/themes/airbnb.css";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

    // initialize() {
    //     this.config = {
    //     };
    // }

    // connect() {
    //     super.connect();
    //     this.fp.setDate(new Date() - 11);
    // }

    // all flatpickr hooks are available as callbacks in your Stimulus controller
    change(selectedDates, dateStr, instance) {
    }
}
