// Remove the alert notification either:
// when the link is clicked
// when the alert is closed with an X

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  clickclose() {
    const Id = this.data.get("badge");
    const host = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
    const url = host + '/badge_notifications/mark_as_read_by_id/' + Id;
    fetch(url, {method: 'get'}) // Clear badge notification
      .then(function() {
        location.reload() // reload page to clear updated the numbered notifications
      });
  }

}
