// For the dashboard cards and map
// Monitors the flight channel and updates cards and the map

import { Controller } from "@hotwired/stimulus"
import consumer from '../channels/consumer';

export default class extends Controller {

  connect() {
    // console.log('Dashboard flight activation controller connected');
    this.channel = consumer.subscriptions.create('FlightChannel', {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    let assessmentId = data.assessment;
    let card = document.getElementById(`card-${assessmentId}`);
    if (card) {
      if (data.status == 'active') {
        console.log('dashboard card activated')
        card.dataset.activated = 'true'
        card.classList.add('card-flight-activated')
        this.mapController.activateShape(assessmentId)
      } else {
        console.log('dashboard card deactivated')
        card.dataset.activated = 'false'
        card.classList.remove('card-flight-activated')
        this.mapController.deactivateShape(assessmentId)
      }
    }
  }

  get mapController() {
    return this.application.getControllerForElementAndIdentifier(document.getElementById("dashboard-map-id"), "dashboard-map")
  }

}
